/*
This file is autogenerated. Don't change it directly.
Instead, change the "addons" setting in your package.json file.
*/

const projectConfigLoader = require('@root/config');
import plonevoltoSlate from '@plone/volto-slate';
import juiziTheme from 'juizi-theme';

const addonsInfo = [{"modulePath":"/home/zope/instances/volto-juizi-com/node_modules/@plone/volto/packages/volto-slate/src","packageJson":"/home/zope/instances/volto-juizi-com/node_modules/@plone/volto/packages/volto-slate/package.json","version":"17.18.2","isPublishedPackage":false,"isRegisteredAddon":false,"name":"@plone/volto-slate","addons":[]},{"modulePath":"/home/zope/instances/volto-juizi-com/src/addons/juizi-theme/src","packageJson":"/home/zope/instances/volto-juizi-com/src/addons/juizi-theme/package.json","version":"0.1.0","isPublishedPackage":false,"isRegisteredAddon":true,"name":"juizi-theme","addons":[]}];
export { addonsInfo };

const safeWrapper = (func) => (config) => {
  const res = func(config);
  if (typeof res === 'undefined') {
    throw new Error("Configuration function doesn't return config");
  }
  return res;
}

const projectConfig = (config) => {
  return typeof projectConfigLoader.default === "function" ? projectConfigLoader.default(config) : config;
}

const load = (config) => {
  const addonLoaders = [plonevoltoSlate, juiziTheme];
  if(!addonLoaders.every((el) => typeof el === "function")) {
    throw new TypeError(
      'Each addon has to provide a function applying its configuration to the projects configuration.',
    );
  }
  return projectConfig(addonLoaders.reduce((acc, apply) => safeWrapper(apply)(acc), config));
};
export default load;
